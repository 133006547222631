@import "assets/scss/colors.scss";

.document-box {
  background-color: #fff;
}

.document-box.vertical-heading {

  position: relative;

  .document-box__heading {
    position: absolute;
    left: 0;
    right: 0;
    transform: rotate(-90deg);
  }

  .document-box__content {
    padding-left: 36px;
  }
}

.document-box.gray {
  background-color: $gainsboro;
}

.document-box__heading {
  background-color: $light_steel_blue;
  padding: 4px 10px;
  text-align: center;
  border-bottom: 1px solid;
}

.document-box__content {
  padding: 10px;
}