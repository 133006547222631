.document-table__td,
.document-table__th {
  flex: 1 1;
  border-right: 1px solid;
  border-bottom: 1px solid;
}

.document-table__td {
  padding: 3px 10px;
  // min-height: 34px;
  overflow-wrap: break-word;
  min-height: 46px;

  input {
    border: 0;
    max-width: 100%;
  }

  > input[type=date] {
    border: 1px solid;
    padding: 2px;
  }

  select,
  > input:not([type=date]) {
    max-width: calc(100% + 10px);
    width: calc(100% + 10px);
    height: 36px;
    margin: 1px -5px;
    border: 1px solid;
    padding: 4px 2px !important;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
  }
}

.document-table__th {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;

  &:first-child {
    border-left: 1px solid;
  }
}

.document-table__td {
  &:first-child {
    border-left: 1px solid;
  }
}

.document-table__th_group {

  display: flex;
  flex-direction: column;

  > div {
    flex: 1;
  }

  > .steal-bg {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
  }

  .document-table__th {
    padding: 3px 10px;
  }

  .document-table__th:first-child {
    border-left: 0;
  }
}

.document-table__tr {
  position: relative;
}

.document-table__remove {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 13px;
  font-weight: bold;
  color: #000;
}

.document-table__bottom {
  display: flex;
  margin-top: 20px;
  // margin-bottom: 30px;

  .button {
    margin-left: auto;
  }
}