@import "assets/scss/colors.scss";

.button {
  display: inline-block;
  text-align: center;
  min-width: 296px;
  padding: 14px 15px;

  border: 1px solid $green;
  text-decoration: none;
  color: #000;

  transition: background .4s;

  &:hover {
    background-color: $green;
  }
}