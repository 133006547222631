.top-buttons-box {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px 25px;
  justify-content: space-between;

  .button {
    margin: 0 6px 6px;
  }
}
