@import 'assets/scss/colors.scss';

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 10px;

}

.breadcrumbs__items {
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;

  a {
    color: #000;
  }

  span {
    &::after {
      content: "/";
      display: inline-block;
      margin: 0 5px;
    }

    &:last-child::after {
      content: '';
    }
  }
}

.breadcrumbs__back {
  margin-left: auto;
  color: $green;
}

.breadcrumbs_text {
  margin-left: auto !important;
  font-size: 106% !important;
}
