@import "assets/scss/colors.scss";

.document-wrap {
  min-width: 1538px;
  width: 1538px;
  overflow: auto;
  min-height: calc(100vh - 152px);
}

.document {
  background-color: #fff;

  .box {
    padding: 4px 8px;
  }

  .label {
    margin-bottom: 10px;
  }

  .gray-color {
    color: #000;
    opacity: 0.5;
  }

  .form-select {
    margin-bottom: 0;

    select {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }

  .form-input {
    margin-bottom: 0;

    input {
      padding: 8px 10px;
    }

    input[type=date] {
      padding: 5px 10px;
    }
  }

  .steal-bg {
    background-color: $light_steel_blue;
  }

  .gray-bg {
    background-color: $gainsboro;
  }

  .border {
    border: 1px solid #000;
  }

  .br {
    border-right: 1px solid #000;
  }

  .bl {
    border-left: 1px solid #000;
  }

  .bt {
    border-top: 1px solid #000;
  }

  .bb {
    border-bottom: 1px solid #000;
  }
}

.document-bottom {
  display: flex;
  margin: 20px 0;

  .button {
    margin-left: auto;
  }
}
