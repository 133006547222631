@font-face {
	font-family: 'Verdana';
	src: url('../fonts/Verdana.ttf');
}

@font-face {
	font-family: 'Verdana Bold';
	src: url('../fonts/VerdanaBold.ttf');
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: 'Verdana', sans-serif;
	background-color: #e4e6e9;
}

a {
	text-decoration: none;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #b4b4b4;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #646464;
	border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #787878;
}

body {
	background-color: #f4f6f8;
	height: 100%;
}

.c-links__item {
	z-index: 10000 !important;
}

img,
svg {
	max-width: 100%;
	height: auto;
}

.MuiDrawer-paper {
	svg.logo {
		path:not(.green) {
			fill: #fff;
		}
	}
}

.flex {
	display: flex;
}

.df-ac {
	display: flex;
	// align-items: center;
	align-items: inherit;
}

.df-centered {
	display: flex;
	align-items: center;
	justify-content: center;
}

.df-column {
	display: flex;
	flex-direction: column;
}

.row {
	display: flex;
	flex-wrap: wrap;
}

//.react-datepicker__input-container input {
//	font-family: 'Verdana', sans-serif;
//}
.react-datepicker__close-icon {
	right: 5px;
}

.MuiIconButton-label {
	min-width: 1.2rem;
}
td[width="180"] {
	white-space: nowrap;
}
