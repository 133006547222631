
.form-input {

  width: 100%;
  margin-bottom: 16px;


  input, label {
    display: block;
    width: 100%;
  }

  label {
    margin-bottom: 10px;
  }

  input {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #c5c5c5;
    min-height: 36px;
    padding: 15px 10px;
  }

  input[readonly] {
    background-color: #f2f2f2;
    outline: none;
  }
}
